import React from 'react';
import './App.css'; // Make sure to import your Tailwind CSS file
import logo from './logo.png'; // Make sure to import your logo

const VapesKyivClub = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-200">
      <header className="bg-gray-900 text-white py-4">
        <h1 className="text-3xl font-bold text-center">Vapes Kyiv Club</h1>
      </header>

      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="flex justify-center mb-8">
          <img src={logo} alt="Vapes Kyiv Club Logo" className="w-50 h-50" />
        </div>

        <div className="max-w-md mx-auto">
          <h2 className="text-2xl font-semibold mb-4">Контакти</h2>
          <ul className="space-y-2">
            <li>
              <a href="https://t.me/mr_vanesko" className="text-blue-600 hover:underline">
                Телеграм Менеджер
              </a>
            </li>
            <li>
              <a href="https://t.me/mr_vanesko_bot" className="text-blue-600 hover:underline">
                Телеграм Магазин
              </a>
            </li>
            <li>
              <a href="https://widgets.binotel.com/w/chat/pages/?wgt=O3iuuF9GW2EBOsF9lgrS" className="text-blue-600 hover:underline">
                Веб-чат 
              </a>
            </li>
          </ul>
        </div>
      </main>

      <footer className="bg-gray-800 text-white py-4 text-center">
        <p>Kyiv 2023</p>
      </footer>
    </div>
  );
};

export default VapesKyivClub;
